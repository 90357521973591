import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { Reveal, Tween } from 'react-gsap'

const AboutQuoteCon = styled.div`
  margin: 6em 6.2vw 7em;

  .number {
    font-size: 300px;
    line-height: 250px;
    margin-left: -50px;
    color: #64BF60;
  }
  .body {
    font-size: 24px;
    line-height: 40px;
    font-family: 'Merriweather', serif;
    letter-spacing: 0.02em;
    color: black;
  }

  ${media.laptop`
    margin: auto 300px auto 400px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

    .text {
      width: 600px;
      margin: auto;
    }

    .number {
      font-size: 500px;
      line-height: 500px;
      color: #64BF60;
      margin-left: 0px;
    }
    .body {
      font-size: 25px;
      width: 550px;
      line-height: 40px;
      letter-spacing: 0.01em;
    }

  `}
`

const AboutQuote = () => {

  return ( 
    <AboutQuoteCon>
        <div className="text">
          <Reveal>
            <Tween
              to={{
                count: 97,
              }}
              ease="none"
              duration={2}
            >
              <p className="number">85</p>
            </Tween>
          </Reveal>
          <Reveal>
            <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1} delay={.5}>
              <p className="body">years of committing to providing the highest quality workwear in South Africa</p>
            </Tween>
          </Reveal>
        </div>
    </AboutQuoteCon>
  )
}

export default AboutQuote
