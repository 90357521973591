import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { Reveal, Tween } from 'react-gsap'

const AboutBlurbCon = styled.div`
    height: auto;

  .text {
    margin: 5em 6.2vw 6em;
  }

  .heading {
    font-size: 20px;
    line-height: 40px;
    font-family: 'Merriweather', serif;
    color: black;
  }
  .body {
    font-size: 15px;
    line-height: 30px;
     
  }

  ${media.laptop`
    min-width: 500px;
    margin: auto 300px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

    .text {
      margin: 30vh auto;
    }

    .heading {
      margin-top: 0;
      font-size: 25px;
      line-height: 40px;
      width: 500px;
      letter-spacing: 0.01em;
    }
    .body {
      font-size: 16px;
      line-height: 26px;
      /* max-width: 570px; */
    }

  `}
`

const AboutBlurb = () => {

  return ( 
    <AboutBlurbCon>
      <div className="text">
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
            <p className="heading">We thrive on serving our clients </p>
          </Tween>
        </Reveal>
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '2vh' }} duration={1} delay={.5}>
            <p className="body">
              ServWorx has evolved from a family-run business in Pietermaritzburg, started in 1923, into the largest independent black-owned provider of rented workwear in South Africa. We are now a 51% black-owned B-BBEE Level 2 business, owned by Letsema holdings.
            </p>
          </Tween>
        </Reveal>
      </div>
    </AboutBlurbCon>
  )
}

export default AboutBlurb
