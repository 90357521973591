import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { graphql, useStaticQuery } from 'gatsby'
import { Reveal, Tween } from 'react-gsap'
import Img from 'gatsby-image'

const AboutCaptionedTwoCon = styled.div`
  margin: 0 0 8em;

  figure {
    margin: 0 5vw;
    position: relative;

    .figure {
      height: 550px;
      overflow: hidden;

      .hero-image, .imgDiv {
        height: 550px;
      }
    }
    
    figcaption {
      text-align: center;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 10px;
      font-weight: normal;
      position: absolute;
      /* top: calc(550px + 20px); */
      top: -20px;
      width: 90vw;
      span {
        color: #64BF60;
      }
    }
  }

  ${media.mobileL`
    height: 500px;
    width: 90vw;
    margin: 0 5vw;
    display: flex;
    justify-content: center;
    figure {
      position: relative;

      .figure {
        height: 400px;
        width: 90vw;
        overflow: hidden;

        .imgDiv, .hero-image {
          width: 90vw;
          height: 400px;
        }
      }
      
      figcaption {
        text-align: center;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 10px;
        margin-bottom: 10px;
        font-weight: normal;
        left: -0px;
        /* top: 405px; */
        span {
          color: #64BF60;
        }
      }
    }
  `}

  ${media.laptop`
    height: 100vh;
    width: auto;
    margin: 0 150px;
    .text {
      transform: rotate(270deg);
      width: 300px;
      height: 10px;
      p {
        margin-top: 100px;
      }
    }

    .imgCon {
      padding: 100px 0;
      figure {
        margin: 0;
        height: calc(100vh - 200px);
        position: relative;
        
        .figure {
          width: 350px;
          height: calc(100vh - 200px);
          overflow:  hidden;
        }

        .hero-image, .imgDiv {
          height: calc(100vh - 200px);
          width: 36vw;
          min-width: 350px;
        }
        
        figcaption {
          display: block;
          font-size: 10px;
          line-height: 10px;
          font-weight: normal;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          height: 100%;
          width: auto;
          max-height: 460px;
          text-align: left;
          position: absolute;
          bottom: 0px;
          left: -30px;
          top: auto;
          -ms-writing-mode: tb-rl;
          -webkit-writing-mode: tb-rl;
          writing-mode: tb-rl;
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg) translateY(0%);
          user-select: none;
          span {
            color: #64BF60;
          }
        }
      }
    }
  `}

  ${media.laptopL`
    .imgCon {
      width: auto;
      padding: 100px 0;
      figure {
        width: auto;
        margin: 0;
        max-height: calc(100vh - 200px);
        position: relative;
        
        .figure {
          width: 450px;
          height: calc(100vh - 200px);
          overflow:  hidden;
        }

        .hero-image, .imgDiv {
          max-height: calc(100vh - 200px);
          height: calc(100vh - 200px);
          width: 36vw;
          min-width: 400px;
          margin: 0;
        }
      }
    }  
  `}
`

const AboutCaptionedTwo = () => {

  const data = useStaticQuery(graphql`
    query {
      contentfulWebsiteImage(identifier: {eq: "about captioned 2"}, sys: {}) {
        imageFile {
          fluid(maxWidth: 980) {
            ...GatsbyContentfulFluid
          } 
        }
        department
        description
      }
    }
  `)

  return ( 
    <div id="foo">
    <AboutCaptionedTwoCon>
      <div className="imgCon">
        <Reveal>
          <figure>
            <Tween from={{ left: '30px' }} duration={1}>
              <figcaption><span>{data.contentfulWebsiteImage.department} </span> / {data.contentfulWebsiteImage.description} </figcaption>
            </Tween>
            <div className="figure">
              <Tween from={{ transform: 'scale(1.2)' }} duration={1} delay={.5}>
                <div className="imgDiv">
                  <Img fluid={data.contentfulWebsiteImage.imageFile.fluid} alt="divider" className="hero-image" />
                </div>
              </Tween>
            </div>
          </figure>
        </Reveal>
      </div>
    </AboutCaptionedTwoCon>
    </div>
  )
}

export default AboutCaptionedTwo
