import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import media from '../../styles/media'
import Img from 'gatsby-image'
import { Reveal, Tween } from 'react-gsap'
import ScrollDarkDiv from '../scroll-dark'

const AboutHeroCon = styled.div`
  height: auto;
  min-width: 100vw;
  color: black;

  .greenbg {
    width: 100vw;
    height: auto;
    min-height: 600px;
    margin: 3em 0;
  }

  .bg {
    width: 100vw ;
    height: 600px;
  }

  .text {
    padding: 9em 6.2vw 0;
    position: absolute;
    top: 5vh;
  }

  span {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  }

  p {
    &.heading {
      font-family: 'Merriweather', serif;
       
      font-size: 30px;
      line-height: 38px;
      max-width: 85vw;
    }
    &.body {
      font-size: 15px;
      line-height: 30px;
      color: #404040;
    }
  }

  figure {
    width: 280px;
    height: 280px;
    position: relative;
    margin: 0 0 0 100px;

    .figure {
      width: 280px;
      overflow: hidden;
      height: 280px;
    }
    
    .hero-image {
      width: 280px;
      height: 280px;
    }
    img {
      max-height: 280px;
    }
    
    figcaption {
      display: block;
      font-size: 10px;
      line-height: 10px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      height: 280px;
      max-height: 280px;
      text-align: left;
      position: absolute;
      bottom: 0px;
      margin-left: -20px;
      margin-right: 10px;
      z-index: 0;
      opacity: 1;
      -ms-writing-mode: tb-rl;
      -webkit-writing-mode: tb-rl;
      writing-mode: tb-rl;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg) translateY(0%);
      user-select: none;
      span {
        color: #64BF60;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }

  ${media.mobileL`
   .greenbg {
    width: 100vw;
    height: auto;
    min-height: 300px;
    margin: 3em 0;
  }
  .bg {
    width: 100vw ;
    height: 200px;
  }

  .text {
    padding: 5em 6.2vw 0;
    position: absolute;
    top: 5vh;
  }

   p {
    &.heading {
      font-size: 20px;
      line-height: 30px;
    }
  }
  figure {
    margin: 0 0 0 45vw;
  }

  `}

  ${media.laptop`
    width: 100vw;
    height: 100vh;
    padding: 18vh auto 20vh;

    .bg {
      width: 100vw ;
      height: 100vh;
    }

    figure {
      position: absolute !important;
      top: 30vh;
      left: 1000px;
      height: 300px;
      width: 320px;
      margin: 0;

      .figure {
        width: 300px;
        height: 300px;
        overflow: hidden;
      }
      
      .hero-image {
        width: 300px;
        height: 300px;
      }
      img {
        width: 300px;
        height: 300px;
        max-height: calc(100vh - 60px);
      }
      
      figcaption {
        display: block;
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        height: 100%;
        max-height: 300px;
        text-align: left;
        position: absolute;
        bottom: -0px;
        left: -15px;
        margin-right: 0px;
        z-index: -1;
        -ms-writing-mode: tb-rl;
        -webkit-writing-mode: tb-rl;
        writing-mode: tb-rl;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg) translateY(0%);
        user-select: none;
        span {
          color: #64BF60;
        font-size: 10px;
        line-height: 10px;
        }
      }
    }

    .greenbg {
      width: 98vw;
      height: 100vh;
    }

    .text {
      padding: 15vh 0 0 10vw;
    }

    span {
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
    }

    p {
      padding: 0;
      &.heading {
        width: 650px;
        font-size: 54px;
        line-height: 75px;
        max-width: 650px;
      }
      &.body {
       font-size: 16px;
        line-height: 26px;
       max-width: 480px;
      }
    }
  `}
`

const AboutHero = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulWebsiteImage(identifier: {eq: "about hero"}, sys: {}) {
          imageFile {
            fluid(maxWidth: 980) {
              ...GatsbyContentfulFluid
            }
          }
          department
          description
        }
      }
    `
  )

  return (
    <>
    <div>
      <Tween from={{ opacity: 0 }} duration={.5} delay={.25}>
        <AboutHeroCon>
          <div className="greenbg">
            <div className="text">
              <Reveal>
                <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
                  <p className="heading">We’re all about teamwork and caring for our customers</p>
                </Tween>
              </Reveal>
              <Reveal>
                <Tween from={{ opacity: 0, paddingTop: '2vh' }} duration={1} delay={.5}>
                  <p className="body">As a South African company, we understand our role in making South Africa prosper for all those who live in it. We strive to use proudly South African products where we can and support our local communities as much as possible.</p>
                </Tween>
              </Reveal>
            </div>
          </div >
          <figure>
            <div className="figure">
              <Tween from={{ transform: 'scale(1.2)' }} duration={1} delay={.5}>
                <Img fluid={data.contentfulWebsiteImage.imageFile.fluid} alt="divider" className="hero-image" />
              </Tween>
            </div>
            <Tween from={{ marginLeft: '30px', opacity: 0 }} duration={1}>
              <figcaption><span>{data.contentfulWebsiteImage.department} </span> / {data.contentfulWebsiteImage.description}</figcaption>
            </Tween>
          </figure>
        </AboutHeroCon>
      </Tween>
              <ScrollDarkDiv/>
    </div>
    </>
  )
}

export default AboutHero