import React from "react"
import SEO from '../components/seo'

import AboutPage from "../components/AboutPage"

const SecondPage = () => (
  <>
    <SEO 
      title="About ServWorx" 
      description="ServWorx is a leading supplier of workwear in South Africa, offering both a rental and laundry service along with workwear clothing sales."
      keywords={[`workwear in south africa`, `workwear clothing`, `workwear industry`]} 
    />
    <AboutPage />
  </>
)

export default SecondPage
