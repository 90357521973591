import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { Reveal, Tween } from 'react-gsap'

const AboutMoreCon = styled.div`
  margin: 9em 6.2vw 7em;

  p {
    font-size: 15px;
    line-height: 30px;
     
  }

  ${media.laptop`
    height: 100vh;
    margin: auto 100px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

    .text {
      width: 500px;
      margin: 0 150px;
      p {
        font-size: 15px;
        line-height: 28px;
      }
    }
  `}

  ${media.laptopL`
    .text {
      width: 500px;
      margin: 35vh 300px 35vh 150px;
      p {
        font-size: 16px;
        line-height: 30px;
      }
    }
  `}
`

const AboutMore = () => {
  return (
    <AboutMoreCon>
      <div className="text">
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '5vw' }} duration={1}>
            <p>As specialist suppliers of workwear and PPE to the food, pharmaceutical and oil &amp; gas industries in South Africa, we put quality and reliability above all else. Our partnership with leading European fabric producer, Klopman, allows us to manufacture and rent specialised workwear clothing that is designed to client specifications. All workwear is 100% compliant to the most stringent hygiene and safety requirements</p>
          </Tween>
        </Reveal>
      </div>
    </AboutMoreCon>
  )
}

export default AboutMore
