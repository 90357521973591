import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { Reveal, Tween } from 'react-gsap'

const AboutMoreTwoCon = styled.div`
  margin: 6em 6.2vw;

  p {
    font-size: 15px;
    line-height: 30px;
     
  }

  ${media.laptop`
    height: 100vh;
    margin: auto 100px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

    .text {
      width: 500px;
      margin: 0 10vw;
      p {
        margin-bottom: 5vh;
        font-size: 15px;
        line-height: 28px;
      }
    }
  `}

  ${media.laptopL`
    .text {
      width: 500px;
      margin: 35vh 300px;
      p {
        margin-bottom: 10vh;
        font-size: 16px;
        line-height: 30px;
      }
    }
  `}
`

const AboutMoreTwo = () => {
  return (
    <AboutMoreTwoCon>
      <div className="text">
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '5vw' }} duration={1}>
            <p>Our core values are centred around providing outstanding service and building strong long-term relationships with our clients. As our client, your needs come first. We strive to learn as much about your business as we can so that we can continue to bring you the highest quality products and services.</p>  
          </Tween>
        </Reveal>
      </div>
    </AboutMoreTwoCon>
  )
}

export default AboutMoreTwo
