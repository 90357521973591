import React from "react"
import styled, { keyframes } from 'styled-components'
import media from '../../styles/media'
import SwiperCore, { Navigation, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import { Reveal, Tween } from 'react-gsap'

const Grow = keyframes`
  0% {
    height: 100vh;
  }
  2% {
    height: 0vh;
  }
  100% {
    height: 100vh;
  }
`

const TimelineCon = styled.div`
  margin: 5em 0 15vh 5vw;
  position: relative;
  
  .horizontal {
    display: none;
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-scrollbar {
    position: absolute;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .swiper-scrollbar-drag {
    height: 5px;
    background: #64BF60;
    margin-top: -2px;
    border-radius: 0;
  }

  .swiper-wrapper {
    margin-bottom: 20px;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: normal;
  }

  p {
    font-size: 15px;
    line-height: 30px;
  }

  .white {
    color: white;
  }

  .overlay {
    position: absolute;
    right: 0;
    bottom: 80px;
    width: 20vw;
    height: 200px;
    background: linear-gradient(268.86deg, #FFFFFF 1.09%, rgba(255, 255, 255, 0.12) 88.19%);
    z-index: 8;
  }

  .instruction {
    display: block;
    color: rgba(64, 64, 64, 0.5);
    font-size: 13px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    text-align: center;
    font-size: 10px;
    line-height: 13px;
    margin-top: 20px;
  }

  .heading {
    color: rgba(0, 0, 0, 0.1);
    letter-spacing: 0.01em;
    font-size: 70px;
    line-height: 91px;
    margin-bottom: 1em;
  }

  h2 {
    font-weight: normal;
    font-family: 'Merriweather', serif;
     
  }

  .swiper-scrollbar {
    width: 70vw;
    margin: 0 10vw;
    height: 3px;
    .swiper-scrollbar-drag {
      height: 5px;
      background: #64BF60;
      margin-top: -1px;
      border-radius: 0;
    }
  }

  ${media.laptop`
    width: 400px;
    height: 100vh;
    margin: 0 300px 0 300px;
    .horizontal {
      display: block;
      font-size: 10px;
      line-height: 13px;
    }
    .vertical {
      display: none;
    }

    .heading {
      color: rgba(0, 0, 0, 0.03);
      letter-spacing: 0.01em;
      font-size: 160px;
      line-height: 208px;
      -ms-writing-mode: tb-rl;
      -webkit-writing-mode: tb-rl;
      writing-mode: tb-rl;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg) translateY(0%);
      position: absolute;
      top: 70px;
      left: -75%;
    }

    span {
      display: block;
      color: rgba(64, 64, 64, 0.5);
      font-size: 13px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      height: 100%;
      max-height: 460px;
      text-align: left;
      position: absolute;
      bottom: 40vh;
      left: -50px;
      z-index: 3;
      -ms-writing-mode: tb-rl;
      -webkit-writing-mode: tb-rl;
      writing-mode: tb-rl;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg) translateY(0%);
      user-select: none;
    }

    .swiper-wrapper {
      min-width: 300px;
      height: 100vh;
      overflow: visible;
      margin-bottom: 0;
      h2 {
        font-size: 40px;
        line-height: 60px;
        font-family: 'Merriweather', serif;
        color: black;
        font-weight: normal;
        &.white {
          color: white;
        }
      } 
      p {
        font-size: 16px;
        line-height: 30px;
        max-width: 280px;
        &.white {
          color: white;
        }
      }
    }

    .swiper-container {
      overflow: visible;
    }

    .swiper-slide {
      max-width: 400px;
      margin-top: 20px;
    }

    .swiper-scrollbar {
      margin: 0;
      opacity: 1 !important
      height: 2px;
      border-radius: 0;
      left: -55px;
      width: 5px;
      position: absolute;
      top: 0vh;
      background-color: rgba(178, 178, 178, 0.5);
      height: 0vh;
      overflow: hidden;
      animation: ${Grow} 1s ease forwards;
      
      .swiper-scrollbar-drag {
        height: 7px;
        background: #64BF60;
        margin-top: -2px;
        border-radius: 0;
      }
    }

    .instruction {
      text-align: center;
      margin-left: -50px;
      margin-top: 30px;
      text-transform: uppercase;
      font-family: sans-serif;
      font-size: 14px;
      line-height: 16px;
    }

  `}
`

const Timeline = () => {

  SwiperCore.use([Navigation, Scrollbar]);

  return ( 
    <TimelineCon id="timeline-container">
    <div className="horizontal-timeline">
      <p className="horizontal  heading">Timeline</p>
      <span className="horizontal">drag to view more</span>
      <Swiper
        slidesPerView={3.5}
        spaceBetween={50}
        grabCursor={true}
        direction={'vertical'}
        scrollbar={{ draggable: true }}
        mousewheelControl={true}
      >
        <SwiperSlide>
          <Reveal>
            <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
              <h2>1923</h2>
              <p>Opening of Volans Master Cleaners (VMC), a bespoke tailoring business, in Pietermaritzburg, South Africa.</p>
            </Tween>
          </Reveal>
        </SwiperSlide>
        <SwiperSlide>
          <Reveal>
            <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1} delay={.5}>
              <h2>1994</h2>
              <p>VMC expands into the flatbed linens sector. </p>
            </Tween>
          </Reveal>
        </SwiperSlide>
        <SwiperSlide>
          <Reveal>
            <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1} delay={1}>
              <h2>1999</h2>
              <p>Services expand to dry cleaning and laundry services. Laundry portion of VMS gets acquired by Bidvest.</p>
            </Tween>
          </Reveal>
        </SwiperSlide>
        <SwiperSlide>
          <Reveal>
            <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1} delay={.5}>
              <h2>2010</h2>
              <p>ServWorx is founded by Luke and Simon Volans, targeting the workwear rental and laundry industry.</p>
            </Tween>
          </Reveal>
        </SwiperSlide>
        <SwiperSlide>
          <Reveal>
            <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1} delay={.5}>
              <h2>2013</h2>
              <p>ServWorx started manufacturing workwear clothing.</p>
            </Tween>
          </Reveal>
        </SwiperSlide>
        <SwiperSlide>
          <Reveal>
            <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1} delay={.5}>
              <h2>2017</h2>
              <p>Letsema, a management consultancy at the heart of a diversified investment group, acquires 51% of ServWorx. </p>
            </Tween>
          </Reveal>
        </SwiperSlide>
        <SwiperSlide>
          <Reveal>
            <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1} delay={.5}>
              <h2>2019</h2>
              <p>Start of the Life Healthcare contract.</p>
            </Tween>
          </Reveal>
        </SwiperSlide>
        <SwiperSlide>
              <h2 className="white">2010</h2>
              <p className="white">ServWorx is founded by Luke and Simon Volans, targeting the workwear rental and laundry industry.</p>
        </SwiperSlide>
      </Swiper>
      
      </div>
      <div className="vertical-timeline">
        <div className="overlay"></div>
        <p className="heading">Timeline</p>
        <Swiper
          slidesPerView={1.25}
          spaceBetween={50}
          grabCursor={true}
          direction={'horizontal'}
          scrollbar={{ draggable: true }}
        >
          <SwiperSlide>
            <h2>1923</h2>
            <p>Opening of Volans Master Cleaners (VMC), a bespoke tailoring business, in Pietermaritzburg, South Africa.</p>
          </SwiperSlide>
          <SwiperSlide>
            <h2>1994</h2>
            <p>VMC expands into the flatbed linens sector. </p>
          </SwiperSlide>
          <SwiperSlide>
            <h2>1999</h2>
            <p>Services expand to dry cleaning and laundry services. Laundry portion of VMS gets acquired by Bidvest.</p>
          </SwiperSlide>
          <SwiperSlide>
            <h2>2010</h2>
            <p>ServWorx is founded by Luke and Simon Volans, targeting the workwear rental and laundry industry.</p>
          </SwiperSlide>
          <SwiperSlide>
            <h2>1923</h2>
            <p>Opening of Volans Master Cleaners (VMC), a bespoke tailoring business, in Pietermaritzburg, South Africa.</p>
          </SwiperSlide>
          <SwiperSlide>
            <h2>1994</h2>
            <p>VMC expands into the flatbed linens sector. </p>
          </SwiperSlide>
          <SwiperSlide>
            <h2>1999</h2>
            <p>Services expand to dry cleaning and laundry services. Laundry portion of VMS gets acquired by Bidvest.</p>
          </SwiperSlide>
          <SwiperSlide>
            <h2>2010</h2>
            <p>ServWorx is founded by Luke and Simon Volans, targeting the workwear rental and laundry industry.</p>
          </SwiperSlide>
        </Swiper>
        <p className="instruction">DRAG TO view more</p>
      </div>
    </TimelineCon>
  )
}

export default Timeline
