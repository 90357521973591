import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { graphql, useStaticQuery } from 'gatsby'
import { Reveal, Tween } from 'react-gsap'
import Img from 'gatsby-image'

const GalleryCon = styled.div`
  height: 600px;
  figure {
    margin: 0;
    height: 550px;
    position: relative;

    .hero-image, .imgDiv {
      height: 550px
    }

    .figure {
      overflow:  hidden;
    }
    
    figcaption {
      text-align: center;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 10px;
      font-weight: normal;
      span {
        color: #64BF60;
      }
    }
  }

  ${media.mobileL`
    height: 500px;
    width: 90vw;
    margin: 0 5vw;
    display: flex;
    justify-content: center;
    figure {
      position: relative;
        width: 90vw;

      .figure {
        height: 500px;
        width: 90vw;
        overflow: hidden;
      }

      .hero-image, .imgDiv {
        height: 500px;
        width: 90vw;
      }
      
      figcaption {
        text-align: center;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 10px;
        margin-bottom: 10px;
        font-weight: normal;
        span {
          color: #64BF60;
        }
      }
    }
  `}

  ${media.laptop`
    width: auto;
    width: 45vw;
    min-width: 500px;
    margin: 0;
    height: 100vh;

    figure {
      height: 100vh;
      margin: 0;
      width: 45vw;
        min-width: 450px;

      .figure {
        width: 45vw;
        min-width: 450px;
        height: 100vh;
        overflow: hidden;
      }

      .hero-image, .imgDiv {
        height: 100vh;
        width: 45vw;
        min-width: 450px;
      }

      figcaption {
        display: block;
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        height: 100%;
        max-height: 460px;
        text-align: left;
        position: absolute;
        bottom: 20vh;
        left: -30px;
        z-index: -1;
        -ms-writing-mode: tb-rl;
        -webkit-writing-mode: tb-rl;
        writing-mode: tb-rl;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg) translateY(0%);
        user-select: none;
        span {
          color: #64BF60;
        }
      }
    }

  `}

  ${media.laptopL`
    min-width: 700px;
    height: 100vh;    

    figure {
      height: 100vh;
      margin: 0;

      .hero-image, .imgDiv {
        height: calc(100vh  + 2px);
        width: auto;
        min-width: 650px;
        margin-top: -1px;
      }
    }
  `}
`

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulWebsiteImage(identifier: {eq: "about divider"}, sys: {}) {
        imageFile {
          fluid(maxWidth: 980) {
            ...GatsbyContentfulFluid
          }
        }
        department
        description
      }
    }
  `)

  return ( 
    <GalleryCon>
      <Reveal>
        <figure>
          <Tween from={{ left: '30px' }} duration={1}>
            <figcaption className="bottom"><span>{data.contentfulWebsiteImage.department} </span> / {data.contentfulWebsiteImage.description}</figcaption>
          </Tween>
          <div className="figure">
            <Tween from={{ transform: 'scale(1.2)' }} duration={1} delay={.5}>
              <div className="imgDiv">
                <Img fluid={data.contentfulWebsiteImage.imageFile.fluid} alt="divider" className="hero-image" />
              </div>
            </Tween>
          </div>
        </figure>
      </Reveal>
    </GalleryCon>
  )
}

export default Gallery
