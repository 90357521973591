import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/navigation/navigation.scss'
import { graphql, useStaticQuery } from 'gatsby'
import { isMobile } from 'react-device-detect'
import Img from 'gatsby-image'

import Left from '../../images/left.webp'
import Right from '../../images/right.webp'
import LeftBlack from '../../images/leftBlack.webp'
import RightBlack from '../../images/rightBlack.webp'

const AboutCaptionedThreeCon = styled.div`
  width: 100vw;

  figure {
    margin: 0;
    height: 300px;
    position: relative;

    .hero-image {
      height: 300px;
      margin-bottom: 25px;
    }
    
    figcaption {
      text-align: center;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 10px;
      font-weight: normal;
      margin-bottom: 0px;
      margin-top: 0;
      &.numbered {
        margin-top: -11px;
      }
      span {
        color: #64BF60;
      }
    }
  }

  .swiper-container {
    width: 100vw;
    .swiper-button-prev {
      z-index: 9;
      color: white;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 348px;
      left: 40vw;
      background-image:  url(${LeftBlack});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: sticky;
      margin-top: 0px;
    }
    .swiper-button-next {
      z-index: 9;
      color: white;
      width: 10px;
      height: 10px;
      right: 2vw;
      position: absolute;
      top: 348px;
      background-image:  url(${RightBlack});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: sticky;
      margin-top: 0px;
      margin-left: 58vw;
    }
    .swiper-button-next::after {
      display: none;
    }
    .swiper-button-prev::after {
      display: none;
    }
  }

  ${media.laptop`
    height: 100vh;
    width: 1200px;
    .text {
      transform: rotate(270deg);
      width: 300px;
      height: 10px;
      p {
        margin-top: 100px;
      }
    }

    .imgCon {
      padding: 50px 0;
      figure {
        margin: 0;
        max-height: calc(100vh - 50px);
        position: relative;

        .hero-image {
          max-height: calc(100vh - 100px);
          height: 85vh;
          width: 70vw;
          max-width: 1200px;
          margin-bottom: 25px;
        }
        
        figcaption {
          display: block;
          font-size: 10px;
          line-height: 10px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          height: 100%;
          text-align: center;
          z-index: 3;
          margin-top: 0;
          user-select: none;
          span {
            color: #64BF60;
          }
        }
      }
    }

    .swiper-container {
      width: 70vw;
      max-width: 1200px;
      .swiper-button-prev {
        z-index: 9;
        color: white;
        cursor: pointer;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 40vh;
        left: 2vw;
        background-image:  url(${Left});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      .swiper-button-next {
        z-index: 9;
        color: white;
        width: 50px;
        height: 50px;
        right: 2vw;
        position: absolute;
        top: 40vh;
        background-image:  url(${Right});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      .swiper-button-next::after {
        display: none;
      }
      .swiper-button-prev::after {
        display: none;
      }
    }
  `}
`

const AboutCaptionedThree = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCarouselImages {
        edges {
          node {
            department
            description
            imageFile {
              fluid(maxWidth: 2000) {
                ...GatsbyContentfulFluid
              } 
            }
          }
        }
      }
    }
  `)

  SwiperCore.use([Navigation, Pagination]);

  const [slideNoTotal, setSlideNoTotal] = useState(0)
  const [activeSlide, setActiveSlide] = useState(0)

  const renderCaro = () => {
    return data.allContentfulCarouselImages.edges.map((caro, index) => {
      return (
        <SwiperSlide key={index}>
          <Img fluid={caro.node.imageFile.fluid} alt="divider" className="hero-image" />
        </SwiperSlide>
      )
    })
  }

  useEffect(() => {
    setSlideNoTotal(data.allContentfulCarouselImages.edges.length)
  }, [data.allContentfulCarouselImages.edges.length])

  return ( 
    <AboutCaptionedThreeCon>
      <div className="imgCon">
        {!isMobile && (
          <figure>
            <Swiper
              slidesPerView={1}
              loop={true}
              navigation
              onSlideChange={(swiper) => setActiveSlide(swiper.realIndex + 1)}
            >   
              {renderCaro()}
            </Swiper>
            {activeSlide && (
              <figcaption>
                {activeSlide}/{slideNoTotal} 
                <span>&nbsp; {data.allContentfulCarouselImages.edges[activeSlide - 1].node.department}&nbsp;</span> / 
                &nbsp;{data.allContentfulCarouselImages.edges[activeSlide - 1].node.description}
              </figcaption>
            )}
          </figure>
        )}
        {isMobile && (
          <figure>
          {activeSlide && (
            <figcaption>
              <span>&nbsp; {data.allContentfulCarouselImages.edges[activeSlide - 1].node.department}&nbsp;</span> / 
              &nbsp;{data.allContentfulCarouselImages.edges[activeSlide - 1].node.description}
            </figcaption>
          )}
          <Swiper
            slidesPerView={1}
            loop={true}
            navigation
            onSwiper={(swiper) => setSlideNoTotal(swiper.imagesToLoad.length/2 - 2)}
            onSlideChange={(swiper) => setActiveSlide(swiper.realIndex + 1)}
          >   
            {renderCaro()}
          </Swiper>
          <figcaption className="numbered">
            {activeSlide}/{slideNoTotal} 
          </figcaption>
        </figure>
        )}
      </div>
    </AboutCaptionedThreeCon>
  )
}

export default AboutCaptionedThree
