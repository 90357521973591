import React from "react"
 
import HorizontalScroll from 'react-scroll-horizontal'
import AboutBlurb from "./blurb-about"
import AboutCaptioned from "./captioned-about"
import AboutCaptionedThree from "./captionedthree-about"
import AboutCaptionedTwo from "./captionedtwo-about"
import Gallery from "./gallery-about"
import AboutHero from "./hero-about"
import AboutMore from "./more-about"
import AboutMoreTwo from "./more-aboutTwo"
import MobNext from "../mobNext"
import AboutQuote from "./quote-about"
import Timeline from "./timeline-about"
import Next from '../next'

const AboutPage = () => {
  const parent  = { height: `100vh`}

  return (
    <>
    <div style={parent} className="horizontal">
      <HorizontalScroll reverseScroll = { true }>
        <div className="panel">
          <AboutHero />
        </div>
        <div className="panel">
          <AboutQuote />
        </div>
        <div className="panel">
          <Gallery />
        </div>
        <div className="panel">
          <AboutBlurb />
        </div>
        <div className="panel">
          <AboutCaptionedThree />
        </div>
        <div className="panel">
          <AboutMore />
        </div>
        <div className="panel">
          <AboutCaptioned />
        </div>
        <div className="panel">
          <AboutMoreTwo />
        </div>
        <div className="panel">
          <Timeline />
        </div>
        <div className="panel">
          <AboutCaptionedTwo />
        </div>
        <div className="panel">
          <Next />
        </div>
      </HorizontalScroll>
    </div>
      
    <div className="vertical">
      <AboutHero />
      <AboutQuote />
      <Gallery />
      <AboutBlurb  />
      <AboutCaptionedThree />
      <AboutMore />
      <AboutCaptioned />
      <AboutMoreTwo />
      <Timeline />
      <AboutCaptionedTwo />
      <MobNext />
    </div>
    </>
  )
}

export default AboutPage
